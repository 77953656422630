import isNull from 'lodash/isNull'
import { call, takeLatest } from 'redux-saga/effects'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Overlay } from '../../lib/toolBoxes/2D/tools'
import { updateOverlayOpacity } from '../../slices/2D'

export function* handleUpdateOverlayOpacity(action: ReturnType<typeof updateOverlayOpacity>) {
    const { id, opacity } = action.payload
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return

    const [overlayTool]: [Overlay] = yield call(manager.getTools, [Overlay.NAME])
    yield call(overlayTool.changeOverlayOpacity, id, opacity / 100)
}

export function* watchForSelectingNewImageOverlayOpacity() {
    yield takeLatest(updateOverlayOpacity.type, handleUpdateOverlayOpacity)
}
