import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, SagaReturnType, takeLatest } from 'redux-saga/effects'
import { SET_ACTIVE_FLOOR_FROM_DOC_CHUNK_ID } from '../../../../actions/drawable'
import {
    fetchAllProjectDocumentMappings,
    fetchProjectContextMarkups,
    fetchProjectDocumentChunks,
    fetchProjectFlags,
    fetchProjectFlagsOptions,
} from '../../../../api/projects-api'
import { getAllFloors } from '../../../../api/takeoff-api'
import { DocumentChunk } from '../../../../models/documentChunk'
import { DocumentMapping } from '../../../../models/documentMapping'
import { FlagOptions } from '../../../../models/flags'
import { Project } from '../../../../models/project'
import { Region } from '../../../../models/region'
import { IToolObject } from '../../../../models/tool'
import { lastActiveProjectDocumentMappingId } from '../../../../shared/constants/project-actions'
import { isFlagsEnabledForProject } from '../../../../utils/project/project-helper-functions'
import {
    initializeDocumentChunks,
    initializeProjectDocumentsFromChunks,
    updateDocumentMappings,
} from '../../../slices/documents'
import { BasicFloorMapping, setAllPossibleMappings } from '../../../slices/mappings'
import { setFlags, setOptions } from '../../../slices/materialFlags'
import { updateRegions } from '../../../slices/region'
import { setToolObjects } from '../../../slices/tools'

export const fetchAllProjectData = createAction<Project>('fetchAllProjectData')

export function* handleFetchAllProjectData({
    payload: { projectStatus, id: projectId, is3D },
}: ReturnType<typeof fetchAllProjectData>) {
    try {
        const mappings: DocumentMapping[] = yield call(fetchAllProjectDocumentMappings, projectId)

        yield put(updateDocumentMappings(mappings))

        const chunks: DocumentChunk[] = yield call(fetchProjectDocumentChunks, projectId)

        yield put(initializeDocumentChunks(chunks))
        yield put(initializeProjectDocumentsFromChunks(chunks))

        const regions: Region[] = yield chunks.flatMap((chunk) => chunk.regions)

        yield put(updateRegions(regions))

        const floors: BasicFloorMapping[] = yield call(getAllFloors)

        yield put(setAllPossibleMappings(floors))

        if (!is3D) {
            // Fetch tool objects from api
            const loadedToolObjects: IToolObject[] = yield call(fetchProjectContextMarkups, projectId)

            yield put(setToolObjects(loadedToolObjects))
        }

        // TODO: set options into admin slice
        const flagsOptions = yield call(fetchProjectFlagsOptions)
        yield put(setOptions({ options: flagsOptions as unknown as FlagOptions }))

        const isFlagsEnabled = isFlagsEnabledForProject(projectStatus.name)

        if (isFlagsEnabled) {
            const projectFlagsResponse: SagaReturnType<typeof fetchProjectFlags> = yield call(
                fetchProjectFlags,
                projectId
            )
            yield put(setFlags({ flags: projectFlagsResponse }))
        }

        const lastProjectOpenPage = sessionStorage.getItem(lastActiveProjectDocumentMappingId)

        if (lastProjectOpenPage) {
            const parsedSavedDocChunkData = JSON.parse(lastProjectOpenPage)
            const savedDocChunkData = parsedSavedDocChunkData[projectId]

            if (savedDocChunkData) {
                // if there was already selected page, keep this page open when we click on Digitizer tab
                yield put({ type: SET_ACTIVE_FLOOR_FROM_DOC_CHUNK_ID, payload: Number(savedDocChunkData) })
                return
            }
        }

        // sort mappings by building id to get the smaller building id
        const sortedMappings = mappings
            .filter((mapping) => mapping.building_id)
            .sort((a, b) => a.building_id - b.building_id)

        if (!sortedMappings?.[0]?.building_id) return

        // get only document mappings from building with smaller building_id
        const buildingDocumentMappings = sortedMappings.filter(
            (sortedMapping) => sortedMapping.building_id === sortedMappings[0]?.building_id
        )

        // sort building document chunks to get the first chunk displayed on Plans tab
        const sortedFirstBuildingDocumentChunks = buildingDocumentMappings.sort(
            (a, b) => a.document_chunk_id - b.document_chunk_id
        )

        // get the document chunk id of first page on Plans tab
        const firstPageDocumentChunkId = sortedFirstBuildingDocumentChunks?.[0]?.document_chunk_id

        if (firstPageDocumentChunkId) {
            yield put({ type: SET_ACTIVE_FLOOR_FROM_DOC_CHUNK_ID, payload: firstPageDocumentChunkId })
        }
    } catch (e) {
        console.error(e)
    }
}

export function* watchForFetchAllProjectData(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(fetchAllProjectData.type, handleFetchAllProjectData)
}
