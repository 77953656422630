import { createAction } from '@reduxjs/toolkit'
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects'
import { UPDATE_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { bulkCreateOpenings, CreateOpeningResponse } from '../../../api/projects-api'
import { Coordinate } from '../../../models/activeDrawable'
import { GeneralDrawableSettings } from '../../../models/activeDrawableSettings'
import { ActiveFloor } from '../../../models/activeFloor'
import { selectActiveFloor } from '../../../reducers/drawable'
import { DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { DEFAULT_SCALE_FACTOR } from '../../../shared/constants/scales'
import { convertScaleFactorLabelEnumToDecimal } from '../../../utils/calculations/scaleConversion/scaleConversion'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Count, PathTool, PolygonTool, RadiusLine, Select, Workspace } from '../../lib/toolBoxes/2D'
import { copyDrawables } from '../../slices/2D'
import { updateToolbarMessage } from '../../slices/common'
import { GeometricDrawable, GeometryGroup, selectDrawableGroupsGeometriesHash } from '../../slices/geometry'
import { selectProjectId } from '../../slices/project'
import {
    DRAWABLE_UNITS_OF_MEASURE,
    ERROR_PASTING_MATERIAL_MESSAGE,
    IMUP2DDrawableLocation,
    MeasurementsToUpdate,
    PASTING_MATERIAL_MESSAGE,
    REGION_ENUMS,
    TOOLBAR_MESSAGE_TIMER,
} from '../../types'
import { selectDrawableLocations } from '../effects/deleteTemporaryDrawableGroups'
import { addNewDrawableLocationToStore, generateMeasurementsToUpdate } from './createDrawableLocation'
import addMetadataToPath from './data-prep/addMetadataToPath'
import { createPathItemFromType } from './drawShapeByType'

export const pasteMaterialAction = createAction<number[]>('pasteMaterial')

export const TEMPORARY_DRAWABLE_ID = -1

export function* createPathAndAddMetadata({
    location,
    colorTool,
    countTool,
    workspaceTool,
    radiusLineTool,
    pathTool,
    polygonTool,
}: {
    location: IMUP2DDrawableLocation & { scaleFactor: number }
    colorTool: Color
    countTool: Count
    workspaceTool: Workspace
    radiusLineTool: RadiusLine
    pathTool: PathTool
    polygonTool: PolygonTool
}) {
    const path: paper.Path = yield call(createPathItemFromType, {
        shapeType: location.shapeType,
        areaOpacity: 0,
        lineOpacity: 0,
        shapeColor: colorTool.createColor('#FFFFFF'),
        coordinates: location.coordinates,
        cutouts: location.cutouts,
        currentScaleFactor: location.scaleFactor,
        countTool,
        workspaceTool,
        radiusLineTool,
        pathTool,
        polygonTool,
    })
    yield call(addMetadataToPath, path, '', location)
    path.data.drawable_id = TEMPORARY_DRAWABLE_ID
    return path
}

export function* updateDrawableLocationsAndOpeningGroups(
    drawable: CreateOpeningResponse,
    drawableGroupId: number,
    drawableGroup: GeometryGroup
) {
    yield call(addNewDrawableLocationToStore, drawable, drawableGroupId)

    const drawableWithDoneState: GeometricDrawable = {
        ...drawable,
        is_marked_done: drawableGroup?.is_marked_done ?? null,
    }

    const newActiveDrawableGroup = {
        ...drawableGroup,
        openings: drawableGroup?.openings
            ? drawableGroup?.openings.concat([drawableWithDoneState])
            : [drawableWithDoneState],
        settings: drawable.opening_group.settings,
    }

    yield put({
        type: UPDATE_OPENING_GROUPS_SUCCESS,
        payload: {
            openingGroups: {
                newGroup: newActiveDrawableGroup,
                originalGroup: drawableGroup,
            },
        },
    })
}

export function* handlePasteMaterial(action: ReturnType<typeof pasteMaterialAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const [selectTool, workspaceTool, colorTool, countTool, radiusLineTool, pathTool, polygonTool]: [
        Select,
        Workspace,
        Color,
        Count,
        RadiusLine,
        PathTool,
        PolygonTool
    ] = yield call(manager.getTools, [
        Select.NAME,
        Workspace.NAME,
        Color.NAME,
        Count.NAME,
        RadiusLine.NAME,
        PathTool.NAME,
        PolygonTool.NAME,
    ])

    const activeFloor: ActiveFloor | null = yield select(selectActiveFloor)

    if (!activeFloor) return

    const projectId: number | null = yield select(selectProjectId)

    if (!projectId) return

    yield put(updateToolbarMessage(PASTING_MATERIAL_MESSAGE))

    yield call(selectTool.clearAllMultiSelections)
    yield put(copyDrawables([]))

    const locations: IMUP2DDrawableLocation[] = yield select(selectDrawableLocations)

    const drawableGroups: Record<string, GeometryGroup> = yield select(selectDrawableGroupsGeometriesHash, true)

    const regionPaths: paper.Path[] = yield call(
        workspaceTool.getItemsWithCriteria,
        'data',
        (data: any) => data.shapeType === REGION_ENUMS.TYPE
    )

    const openingIMUPLocations: (IMUP2DDrawableLocation & { scaleFactor: number })[] = yield action.payload.reduce(
        (accLocations, locationId) => {
            const location = locations.filter((geo) => geo.opening_location_id === locationId)[0]
            if (location) {
                // Determine if this drawable is inside a region so we can scale it appropriately
                let thisRegionGroup: paper.Path | null = null
                for (const regionPath of regionPaths) {
                    for (const c of location.coordinates) {
                        if (!regionPath.contains(workspaceTool.generatePoint(c))) {
                            break
                        }
                        thisRegionGroup = regionPath
                    }
                }
                const scaleFactor = thisRegionGroup
                    ? thisRegionGroup.data.scale
                    : activeFloor?.scale_factor ?? DEFAULT_SCALE_FACTOR

                accLocations.push({
                    ...location,
                    region_id: thisRegionGroup ? thisRegionGroup.id : thisRegionGroup,
                    scaleFactor: convertScaleFactorLabelEnumToDecimal(scaleFactor),
                })
            }
            return accLocations
        },
        [] as (IMUP2DDrawableLocation & { scaleFactor: number })[]
    )

    let paths: paper.Path[] = yield all(
        openingIMUPLocations.map((loc) => {
            return createPathAndAddMetadata({
                location: loc,
                colorTool,
                countTool,
                workspaceTool,
                polygonTool,
                pathTool,
                radiusLineTool,
            })
        })
    )

    const openingDataToCreate = openingIMUPLocations.reduce(
        (bulkCreateOpeningsData, location, i) => {
            const path = paths[i]
            bulkCreateOpeningsData.push({
                opening_group_id: location.opening_group_id,
                coordinates: location.coordinates,
                document_chunk_id: activeFloor.document_chunk.id,
                measurements: generateMeasurementsToUpdate({
                    coordinates: location.coordinates,
                    path,
                    settingsAndType: {
                        type: location.drawing_type,
                        settings: location.settings as GeneralDrawableSettings,
                    },
                    scaleFactor: location.scaleFactor,
                    pdfScale: activeFloor.document_chunk.pdf_scale || 1,
                    xCalibrationFactor: activeFloor.document_chunk.calibration_factor_x,
                    yCalibrationFactor: activeFloor.document_chunk.calibration_factor_y,
                    dpi: activeFloor.document_chunk.dpi ?? null,
                }),
                settings: {
                    unit_of_measure:
                        path.data.shapeType === DRAWING_TYPES.SECTION || path.data.shapeType === DRAWING_TYPES.RADIUS
                            ? DRAWABLE_UNITS_OF_MEASURE.line
                            : path.data.shapeType === DRAWING_TYPES.AREA
                            ? DRAWABLE_UNITS_OF_MEASURE.area
                            : DRAWABLE_UNITS_OF_MEASURE.count,
                    shape_type: path.data.shapeType,
                },
                region_id: location.region_id,
                additional_data: location.additionalData ?? {},
                ai_suggestion_id: path.data.aiSuggestion?.id,
                floor_hash: activeFloor.hash,
            })
            return bulkCreateOpeningsData
        },
        [] as {
            opening_group_id: number
            coordinates: Coordinate[]
            document_chunk_id: number
            measurements: MeasurementsToUpdate
            settings: {
                unit_of_measure: string
                shape_type?: string
            }
            region_id: number | null
            additional_data?: Record<string, any>
            ai_suggestion_id?: string
            floor_hash: string
        }[]
    )

    yield all(paths.map((path) => call([path, path.remove])))

    try {
        const response: CreateOpeningResponse[] = yield call(bulkCreateOpenings, projectId, openingDataToCreate)

        yield all(
            response.map((res) =>
                call(
                    updateDrawableLocationsAndOpeningGroups,
                    res,
                    res.opening_group_id,
                    drawableGroups[res.opening_group_id]
                )
            )
        )

        yield put(updateToolbarMessage(null))
    } catch (e) {
        console.log(e)
        yield put(updateToolbarMessage(ERROR_PASTING_MATERIAL_MESSAGE))
        yield delay(TOOLBAR_MESSAGE_TIMER)
        yield put(updateToolbarMessage(null))
    }
}

export function* watchForPasteMaterial() {
    yield takeEvery(pasteMaterialAction.type, handlePasteMaterial)
}
