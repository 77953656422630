import { call, takeEvery } from 'redux-saga/effects'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select } from '../../lib/toolBoxes/2D'
import { multiSelectDrawables } from '../../slices/2D'

export function* handleMultiSelectingMaterial({ payload }: ReturnType<typeof multiSelectDrawables>) {
    if (payload.length === 0) {
        const paperManager: PaperManager | null = yield call(managers.get2DManager)

        if (!paperManager) return

        const [selectTool]: [Select] = yield call(paperManager.getTools, [Select.NAME])

        yield call(selectTool.clearAllMultiSelections, false)
    }
}

export function* watchForMultiSelectingMaterial() {
    yield takeEvery(multiSelectDrawables.type, handleMultiSelectingMaterial)
}
