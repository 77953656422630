import { UiSchema } from '@rjsf/core'
import { calculateLFMeasurementOfMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import {
    handleSolidBeamCleanUp,
    solidBeam,
    solidBeamUIOrder,
    solidBeamUiSchema,
} from '../shared/constants/solid-beam-schema-data'
import { deckOrPorch } from '../utils/formSchema/constants'

const labelOther = 'other'

export const uiSchema: UiSchema = {
    'ui:order': [
        'location',
        'material',
        'glulam_type',
        ...solidBeamUIOrder,
        'dimension',
        'width',
        'width_other',
        'height',
        'height_other',
        'length',
        'pitch',
        'pitch_other',
        'plies',
        'plies_other',
        'is_treated',
    ],
    location: {
        'ui:title': 'Location',
        'ui:placeholder': 'Select Location',
    },
    material: {
        'ui:title': 'Beam Material',
        'ui:placeholder': 'Select Beam Material',
    },
    glulam_type: {
        'ui:title': 'Glulam Type',
        'ui:placeholder': 'Select Glulam Type',
    },
    dimension: {
        'ui:title': 'Dimension',
        'ui:placeholder': 'Select Dimension',
    },
    width: {
        'ui:title': 'Width',
        'ui:placeholder': 'Select Width',
    },
    width_other: {
        'ui:title': 'Other Width',
        'ui:placeholder': 'Enter Width in inches',
    },
    height: {
        'ui:title': 'Height',
        'ui:placeholder': 'Select Height',
    },
    height_other: {
        'ui:title': 'Other Height',
        'ui:placeholder': 'Enter Height in inches',
    },
    length: {
        'ui:title': 'Length',
    },
    pitch: {
        'ui:title': 'Pitch',
        'ui:placeholder': 'Select Pitch',
    },
    pitch_other: {
        'ui:title': 'Pitch Other',
    },
    plies: {
        'ui:title': 'Plies',
        'ui:placeholder': 'Select Plies',
    },
    plies_other: {
        'ui:title': 'Plies Other',
        'ui:placeholder': 'Select Other Plies',
    },
    is_treated: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Bundle Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Bundle Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Bundle Other Floor Level',
    },
    ...solidBeamUiSchema,
}

const onValidate = () => {}

/**
 * Augment the drawables of the from based on the new settings
 * @returns
 * @param input
 */
function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
}): ActiveDrawable[] {
    const { drawables, newSettings } = input

    return drawables.map((drawable) => {
        drawable.settings.quantity = calculateLFMeasurementOfMaterial(drawable.settings.linear_total, {
            type: DRAWABLE_TYPES.FLUSH_BEAM,
            settings: newSettings,
        })
        return drawable
    })
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (formData.material !== 'DIMENSIONAL LUMBER') {
        newSettings.length = null
    }

    if (formData.material !== solidBeam) {
        newSettings.bearing = null
    }

    if (formData.pitch === labelOther) {
        newSettings.pitch = formData.pitch_other
    }

    newSettings.pitch_other = null

    if (newSettings.height === labelOther) {
        newSettings.height = newSettings.height_other
    }

    newSettings.height_other = null

    if (newSettings.width === labelOther) {
        newSettings.width = newSettings.width_other
    }

    newSettings.width_other = null

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_location = null
        newSettings.bundle_floor_level = null
    }

    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }

    return handleSolidBeamCleanUp(formData, newSettings)
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form
