import { createAction } from '@reduxjs/toolkit'
import { CancelTokenSource } from 'axios'
import { call, cancel, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { fetchProjectSuccess } from '../../../actions'
import { getProjectById } from '../../../api/projects-api'
import { getTakeoffData } from '../../../api/takeoff-api'
import { AzureFeatureFlag } from '../../../models/azureFeatureFlags'
import { Project } from '../../../models/project'
import { IntermediateTakeoffResponse, PreparedTakeoff, TakeOffResponse } from '../../../models/takeoff'
import { AzureFeatureFlagIds } from '../../../shared/constants/azure-feature-flags'
import { isAzureFeatureFlagEnabled } from '../../../shared/services/azure-feature-flag-services/azure-feature-flag-services'
import { getFormattedDatetime } from '../../../utils/datetime'
import { setTakeoffData } from '../../slices/2D'
import { selectAzureFeatureFlags } from '../../slices/flags'
import {
    selectIsTakeOffLoading,
    selectLastLoadingTime,
    setIsTakeOffLoading,
    setLastLoadingTime,
    setPageDataLoading,
} from '../../slices/loading'
import { selectProject } from './createDrawableLocation'

export const fetchTakeoffData = createAction<{ projectId: number; timeout: number; cancelSource: CancelTokenSource }>(
    'fetchTakeoffData'
)
export const stopPollingForNewTakeoffData = createAction('stopFetchingTakeoffData')

const BUILDING_NAME_GROUPING_KEY = 'buildingName'
const CORE_PACKAGE_GROUPING_KEY = 'corePackage'
const TIMEOUT_IN_MS = 10000

// Function to group the array by 'field1' and 'field2'
function groupByTwoFields(
    array: Omit<IntermediateTakeoffResponse, 'measurementFile'>[],
    field1: string,
    field2: string
) {
    const groupedByField1 = array.reduce((result, item) => {
        const key1 = item[field1]
        if (!result[key1]) {
            result[key1] = {} as Record<string, Omit<IntermediateTakeoffResponse, 'measurementFile'>[]>
        }
        return result
    }, {} as Record<string, Record<string, Omit<IntermediateTakeoffResponse, 'measurementFile'>[]>>)

    return array.reduce((result, item) => {
        const key1 = item[field1]
        const key2 = item[field2]
        if (!result[key1][key2]) {
            result[key1][key2] = []
        }
        result[key1][key2].push(item)
        return result
    }, groupedByField1)
}

function finalPrepareTakeoffData(groupedArray) {
    const groupedUpdatedTakeoffData: {
        description: string
        quantity?: string
        sku?: string
        isBuilding?: boolean
        isCorePackage?: boolean
    }[] = []

    Object.keys(groupedArray).forEach((buildingName) => {
        // get the building name for row
        groupedUpdatedTakeoffData.push({ description: buildingName, isBuilding: true })

        Object.keys(groupedArray[buildingName]).forEach((corePackage) => {
            // get the core package name for row
            groupedUpdatedTakeoffData.push({ description: corePackage, isCorePackage: true })

            groupedArray[buildingName][corePackage].forEach((item) => {
                // get the needed data for row
                groupedUpdatedTakeoffData.push({
                    description: item.description,
                    quantity: item.quantity,
                    sku: item.sku,
                })
            })
        })
    })

    return groupedUpdatedTakeoffData
}

function groupByMeasurementFile(data: IntermediateTakeoffResponse[]) {
    return data.reduce((acc, obj) => {
        const { measurementFile, ...rest } = obj
        if (!acc[measurementFile]) {
            acc[measurementFile] = []
        }
        acc[measurementFile].push(rest)
        return acc
    }, {} as Record<string, Omit<IntermediateTakeoffResponse, 'measurementFile'>[]>)
}

export function* handleFetchTakeoffData({ payload }: ReturnType<typeof fetchTakeoffData>) {
    const { projectId, timeout, cancelSource } = payload

    if (!projectId) return

    yield put(setPageDataLoading(true))

    const newTakeoffData: TakeOffResponse | undefined = yield call(getTakeoffData, projectId, timeout, cancelSource)

    if (newTakeoffData) {
        const lastUpdatedTime: string = getFormattedDatetime(Date.now(), 'YYYY-MM-DD H:mm:ss')
        yield put(setLastLoadingTime(lastUpdatedTime))
        yield put(setTakeoffData({ projectId, takeoffData: prepareTakeoffData(newTakeoffData) }))
    }

    yield put(setPageDataLoading(false))
}

export function prepareTakeoffData(takeOffData: TakeOffResponse): PreparedTakeoff[] {
    const preparedNewTakeoffData: IntermediateTakeoffResponse[] = takeOffData.data.AllParts.reduce((parts, part) => {
        if (part.Quantity > 0) {
            parts.push({
                buildingName: part.BuildingName,
                corePackage: part.CorePackage,
                measurementFile: part.MeasurementFile,
                description: part.Description,
                quantity: part.Quantity,
                sku: part.SKU,
            })
        }
        return parts
    }, [] as IntermediateTakeoffResponse[])

    const takeoffDataGropedByMeasurementFile = groupByMeasurementFile(preparedNewTakeoffData)

    const groupDataInsideMeasureFiles: Record<
        string,
        Record<string, Record<string, Omit<IntermediateTakeoffResponse, 'measurementFile'>[]>>
    > = {}

    Object.keys(takeoffDataGropedByMeasurementFile).forEach((measureFileName) => {
        groupDataInsideMeasureFiles[measureFileName] = groupByTwoFields(
            takeoffDataGropedByMeasurementFile[measureFileName],
            BUILDING_NAME_GROUPING_KEY,
            CORE_PACKAGE_GROUPING_KEY
        )
    })

    const finalGroupedData: PreparedTakeoff[] = []

    Object.keys(groupDataInsideMeasureFiles).forEach((measureFileName) => {
        const res = finalPrepareTakeoffData(groupDataInsideMeasureFiles[measureFileName])
        finalGroupedData.push({ description: measureFileName, isMeasureFile: true }, ...res)
    })

    return finalGroupedData
}

export function* stopPollingAndUpdateData(
    generatedTakeoff: TakeOffResponse & {
        generated_at: number
    },
    newFormattedProjectTime: string,
    projectId: number
) {
    const preparedData: PreparedTakeoff[] = yield call(prepareTakeoffData, generatedTakeoff)
    yield put(setTakeoffData({ projectId: projectId, takeoffData: preparedData }))
    yield put(setLastLoadingTime(newFormattedProjectTime))
    yield put(setIsTakeOffLoading(false))
    yield put(setPageDataLoading(false))
    yield put(stopPollingForNewTakeoffData())
}

export function* pollForTakeoffData() {
    yield put(setPageDataLoading(true))
    yield put(setIsTakeOffLoading(false))

    while (true) {
        const project: Project | null = yield select(selectProject)
        const lastUpdatedTime: string | null = yield select(selectLastLoadingTime)

        if (!project) return

        if (!project.outputs?.generated_takeoff) {
            const isNewTakeoffDataLoading: boolean = yield select(selectIsTakeOffLoading)

            if (!project.outputs?.errors) {
                // Check that if we have already triggered the backend to
                // generate the takeoff if we have then fetch the project data
                // if not trigger it and wait till next timeout to pull down the data
                if (isNewTakeoffDataLoading) {
                    const newProjectData: Project = yield call(getProjectById, project.id)
                    yield put(fetchProjectSuccess(newProjectData))
                    if (newProjectData.outputs?.generated_takeoff?.generated_at) {
                        const newFormattedProjectTime = getFormattedDatetime(
                            newProjectData.outputs?.generated_takeoff.generated_at,
                            'YYYY-MM-DD H:mm:ss'
                        )

                        if (newFormattedProjectTime !== lastUpdatedTime) {
                            yield call(
                                stopPollingAndUpdateData,
                                newProjectData.outputs.generated_takeoff,
                                newFormattedProjectTime,
                                project.id
                            )
                        }
                    }
                } else {
                    yield call(getTakeoffData, project.id, undefined)
                    yield put(setIsTakeOffLoading(true))
                }
            } else {
                if (isNewTakeoffDataLoading) {
                    yield put(setPageDataLoading(false))
                    yield put(stopPollingForNewTakeoffData())
                } else {
                    // Recalculate material list was clicked
                    // reset generated material list and will call the API
                    // to recalculate
                    yield put(
                        fetchProjectSuccess({
                            ...project,
                            outputs: { ...project.outputs, errors: undefined, generated_takeoff: undefined },
                        })
                    )
                    yield put(setTakeoffData({ projectId: project.id, takeoffData: [] }))
                }
            }
        } else {
            // This covers the initial loading of the takeoff tab
            // when there is already a generated output in the project
            const formattedProjectTime = getFormattedDatetime(
                project.outputs?.generated_takeoff.generated_at,
                'YYYY-MM-DD H:mm:ss'
            )

            if (!lastUpdatedTime) {
                yield call(
                    stopPollingAndUpdateData,
                    project.outputs.generated_takeoff,
                    formattedProjectTime,
                    project.id
                )
            } else {
                // Recalculate material list was clicked
                // reset generated material list and will call the API
                // to recalculate
                yield put(
                    fetchProjectSuccess({
                        ...project,
                        outputs: { ...project.outputs, errors: undefined, generated_takeoff: undefined },
                    })
                )
                yield put(setTakeoffData({ projectId: project.id, takeoffData: [] }))
            }
        }

        yield delay(TIMEOUT_IN_MS)
    }
}

function* forkToRightSagaBasedOnFeatureFlag(action: ReturnType<typeof fetchTakeoffData>) {
    const azureFeatureFlags: AzureFeatureFlag[] | null = yield select(selectAzureFeatureFlags)

    const isNewGatewayEnabled: boolean = yield call(
        isAzureFeatureFlagEnabled,
        azureFeatureFlags,
        AzureFeatureFlagIds.new_gateway
    )

    if (!isNewGatewayEnabled) {
        yield fork(handleFetchTakeoffData, action)
    } else {
        const quotePollTask = yield fork(pollForTakeoffData)
        yield take(stopPollingForNewTakeoffData.type)
        yield cancel(quotePollTask)
    }
}

export function* watchForFetchTakeoffData() {
    yield takeEvery(fetchTakeoffData.type, forkToRightSagaBasedOnFeatureFlag)
}
