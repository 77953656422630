import isNull from 'lodash/isNull'
import { distinctUntilChanged, map, takeWhile } from 'rxjs'
import { DRAWING_TYPES } from '../../../../../../shared/constants/drawable-types'
import { applyScaleFactorToPathArea } from '../../../../../../utils/calculations/scaleConversion/scaleConversion'
import { ToolsState, initialToolsState } from '../../../../../slices/tools'
import { Cursors, IMUPState, PaperToolConfig, VIEW_MODE } from '../../../../../types'
import { SNAPPING_TOLERANCE } from '../../../../../utils/constants'
import {
    getElementsWithinDistance,
    getSnapPaths,
    getSnapPointOnDraw,
    getSnapPosition,
    pathsIntersect,
} from '../../../../utils/Snapping'
import { PathTool } from '../path/Path.tool'

/**
 * Rectangle.tool.tsx
 * Creates a corner to corner rectangle upon mouse drag
 */
export class Rectangle extends PathTool {
    static NAME = 'RECTANGLE'
    static CURSOR = Cursors.CROSSHAIR

    protected startingPoint: paper.Point | null = null

    protected color: ToolsState['color'] = initialToolsState.color
    protected strokeWidth = 0 // set to zero for the stroke width of the created rectangle
    protected opacity: ToolsState['areaOpacityValue'] = initialToolsState.areaOpacityValue
    protected currentPaperId: number | null = null
    protected snappingEnabled: boolean = false
    protected itemsToIgnoreHitFilter: paper.Item[] | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Rectangle.NAME
        this.cursor = Rectangle.CURSOR

        this.mediator
            .get$()
            .pipe(
                takeWhile(({ common: { activeMode } }: IMUPState) => activeMode === VIEW_MODE.Markup2D),
                map(({ tools: { areaOpacityValue, snappingEnabled } }: IMUPState) => ({
                    areaOpacityValue,
                    snappingEnabled,
                })),
                distinctUntilChanged()
            )
            .subscribe(({ areaOpacityValue, snappingEnabled }) => {
                this.opacity = areaOpacityValue
                this.snappingEnabled = snappingEnabled
            })
    }

    private getSnapPoint = (event: paper.MouseEvent): paper.Point => {
        if (this.startingPoint instanceof this.paper.Point) {
            const testPath = new this.paper.Path.Rectangle(this.startingPoint, event.point)

            const items = this.paper.project.getItems({
                data: (data) => (data?.drawable_id || data?.aiSuggestion?.id) && !data?.ignoreHitFilter,
                id: (id: number) => id !== testPath.id,
            })

            const paths = getSnapPaths(this.paper, items)

            let eventPoint = event.point

            paths.forEach((path) => {
                if (pathsIntersect(testPath, path, SNAPPING_TOLERANCE)) {
                    const calculatedSnapPosition = getSnapPosition(
                        this.paper,
                        path,
                        testPath,
                        SNAPPING_TOLERANCE,
                        event.point
                    )

                    if (!isNull(calculatedSnapPosition)) {
                        eventPoint = calculatedSnapPosition
                    }
                }
            })

            return eventPoint
        }

        return event.point
    }

    protected draw(color: paper.Color | null, from: paper.Point, to: paper.Point): paper.Path.Rectangle {
        const path = new this.paper.Path.Rectangle(from, to)

        path.opacity = this.opacity
        path.fillColor = color
        path.data.shapeType = DRAWING_TYPES.AREA

        this.currentPaperId = path.id

        return path
    }

    /**
     * Clear the points that are being drawn with the tool
     */
    cancel = () => {
        const rect = this.paper.project.getItem({ id: this.currentPaperId })
        if (rect) rect.remove()

        this.startingPoint = null

        // remove ignore hit filter when we cancel to be able to snap to elements
        if (!isNull(this.itemsToIgnoreHitFilter)) {
            this.itemsToIgnoreHitFilter.forEach((item) => (item.data.ignoreHitFilter = false))
        }

        this.itemsToIgnoreHitFilter = null

        this.setState('common', { cursor: this.cursor, tooltip: { title: '', visible: false, color: '#000000' } })
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        if (this.startingPoint) {
            this.startingPoint = null
            if (this.currentPaperId) this.setState('2D', { drawablesToCreate: [this.currentPaperId] })
            this.currentPaperId = null
            // The shape has been created at this point, tool state has been reset
        } else {
            this.startingPoint = event.point

            if (this.snappingEnabled) {
                const nearElements = getElementsWithinDistance(this.paper, event.point, SNAPPING_TOLERANCE)

                // ignore hit filter because we will automatically snap
                nearElements.forEach((element) => (element.data.ignoreHitFilter = true))

                // set items to ignore hit filter to remove it on cancel
                this.itemsToIgnoreHitFilter = nearElements

                if (!!nearElements.length) {
                    this.startingPoint = getSnapPointOnDraw(this.paper, event.point, nearElements as paper.Path[])
                }
            }

            this.setScaleFromPointClick(event.point)

            // Begin to draw the shape
            this.draw(new this.paper.Color(this.color), this.startingPoint, this.startingPoint)
        }
    }

    onMouseUp = (event) => {
        this.setState('common', { cursor: this.cursor, tooltip: { title: '', visible: false, color: '#000000' } })
    }

    onMouseDrag = (event) => {
        if (this.toolPanning(event)) return
    }

    onMouseMove = (event: paper.MouseEvent): void => {
        if (this.startingPoint instanceof this.paper.Point) {
            // remove the previous rectangle that is no longer relevant
            this.paper.project.activeLayer.lastChild.remove()

            let eventPoint = event.point

            if (this.snappingEnabled) {
                const nearElements = getElementsWithinDistance(this.paper, event.point, SNAPPING_TOLERANCE)

                if (!!nearElements.length) {
                    eventPoint = getSnapPointOnDraw(this.paper, event.point, nearElements as paper.Path[])
                }
            }

            const rect = this.draw(new this.paper.Color(this.color), this.startingPoint, eventPoint)

            this.mediator.mediate('common', {
                tooltip: {
                    title: `Area: ${applyScaleFactorToPathArea({
                        pxValue: rect.area,
                        scaleFactor: this.scaleFactor,
                        dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                        xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                        yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,

                        pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                    }).toFixed(2)} sqft`,
                    visible: true,
                    color: '#000000',
                },
            })
        }
    }
}

export default Rectangle
