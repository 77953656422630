import { ActionReducerMapBuilder, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../stores'
import { availableMode } from './common'

/**
 * Loading state
 *
 * Increment when requests are loading and
 * decrement when they complete and when equal to 0, network loading is false.
 * Scene rendering is loading or not.
 */
export type LoadingState = {
    numberOfPendingRequests: number
    sceneRendering: boolean
    imageRotationInProgress: boolean
    pageDataLoading: boolean
    lastLoadingTime: string | null
    isTakeoffInProgress: boolean
}

export const initialLoadingState: LoadingState = {
    numberOfPendingRequests: 0,
    sceneRendering: false,
    imageRotationInProgress: false,
    pageDataLoading: true,
    lastLoadingTime: null,
    isTakeoffInProgress: false,
}

const incrementPendingRequests = (state: LoadingState): void => {
    state.numberOfPendingRequests++
}

const decrementPendingRequests = (state: LoadingState): void => {
    state.numberOfPendingRequests > 0 && state.numberOfPendingRequests--
}

function handleSetImageRotationInProgress(state: LoadingState, action: PayloadAction<boolean>) {
    state.imageRotationInProgress = action.payload
}

function handleSetPageDataLoading(state: LoadingState, action: PayloadAction<boolean>) {
    state.pageDataLoading = action.payload
}

function handleSetLastLoadingTime(state: LoadingState, action: PayloadAction<string | null>) {
    state.lastLoadingTime = action.payload
}

function handleSetIsTakeOffLoading(state: LoadingState, action: PayloadAction<boolean>) {
    state.isTakeoffInProgress = action.payload
}

const reducers = {
    requestPending: incrementPendingRequests,
    requestCompleted: decrementPendingRequests,
    setImageRotationInProgress: handleSetImageRotationInProgress,
    sceneRendering: (state: LoadingState): void => {
        state.sceneRendering = true
    },
    sceneRendered: (state: LoadingState): void => {
        state.sceneRendering = false
    },
    resetLoading: (state: LoadingState): void => {
        state.numberOfPendingRequests = 0
        state.sceneRendering = false
    },
    setPageDataLoading: handleSetPageDataLoading,
    setLastLoadingTime: handleSetLastLoadingTime,
    setIsTakeOffLoading: handleSetIsTakeOffLoading,
}

const extraReducers = (builder: ActionReducerMapBuilder<LoadingState>) => {
    builder.addCase(availableMode, decrementPendingRequests)
}

const loadingSlice = createSlice({ name: 'loading', initialState: initialLoadingState, reducers, extraReducers })

export const {
    requestPending,
    requestCompleted,
    resetLoading,
    sceneRendered,
    sceneRendering,
    setImageRotationInProgress,
    setPageDataLoading,
    setLastLoadingTime,
    setIsTakeOffLoading,
} = loadingSlice.actions

export const selectNetworkLoadingState = createSelector(
    (state: RootState) => state.IMUP.loading.numberOfPendingRequests,
    (numberOfRequests) => numberOfRequests > 0
)

export const selectPageDataLoading = createSelector(
    (state: RootState) => state.IMUP.loading.pageDataLoading,
    (pageDataLoading) => pageDataLoading
)

export const selectLastLoadingTime = createSelector(
    (state: RootState) => state.IMUP.loading.lastLoadingTime,
    (lastLoadingTime) => lastLoadingTime
)

export const selectIsTakeOffLoading = createSelector(
    (state: RootState) => state.IMUP.loading.isTakeoffInProgress,
    (isLoading) => isLoading
)

export default loadingSlice
