import axios from 'axios'
import React, { useState } from 'react'
import ModalDialogComponent from '../../shared/components/modal-dialog/modal-dialog'
import { HTTPStatusCode } from '../../shared/constants/http-status-codes'

// Functionality is written as React component to be able to use error-boundary hook
const AxiosErrorHandler = (): React.ReactElement => {
    const [errors, setErrors] = useState({})
    const [isNetworkError, setIsNetworkError] = useState(false)

    axios.interceptors.response.use(
        function (response) {
            // Do something with response data
            return response
        },
        function (error) {
            // when the error is canceled by axios we receive only a message but we send an object so we parse it
            const preparedError = axios.isCancel(error) ? JSON.parse(error.message) : error

            if (preparedError?.customStatus === HTTPStatusCode.CANCELED) {
                // do not handle canceled request by a user
            } else if (preparedError?.customStatus) {
                setErrors({
                    customErrorMessage: {
                        response: {
                            status: preparedError.customStatus,
                            data: {
                                errors: [{ message: preparedError.message }],
                            },
                        },
                    },
                })
            } else if (!preparedError.response?.status) {
                setIsNetworkError(true)
            } else {
                setErrors({
                    ...errors,
                    [Object.values(errors).length]: preparedError,
                })
            }

            return Promise.reject(preparedError)
        }
    )

    const totalErrors = Object.values(errors).length
    const modalBody = (): string | React.ReactElement => {
        if (isNetworkError) {
            return 'Network Error'
        }

        if (totalErrors) {
            return (
                <>
                    {Object.values(errors).map((error: any, index) => {
                        const code = error?.response?.status
                        // Display run-time or back-end massage
                        const msg = code
                            ? error.response?.data?.errors?.map((err) => <div>{err.message}</div>)
                            : error.message

                        return (
                            <div key={index}>
                                <div>{code ? <b>Code: {code}</b> : ''}</div>
                                <br></br>
                                <div>{msg ? <b>Message: {msg}</b> : ''}</div>
                                <br></br>
                            </div>
                        )
                    })}
                </>
            )
        }

        return 'No errors found'
    }

    return isNetworkError || totalErrors ? (
        <ModalDialogComponent
            title="Error"
            body={modalBody()}
            closeButton
            handleClose={(): void => (isNetworkError ? setIsNetworkError(false) : setErrors({}))}
        />
    ) : (
        <></>
    )
}

export default AxiosErrorHandler
