import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { IMasterSetPlanOption } from '../../../models/masterSetPlan'
import { selectMasterSetPlanOptions, updateSingleMasterSetPlanState } from '../../slices/masterSetPlan'
import { IMUP2DCoordinatesToUpdate } from '../../types'

export const updateMasterSetPlanFromId = createAction<{
    masterSetPlanId: number
    coordinatesToUpdate?: IMUP2DCoordinatesToUpdate
    propertiesToUpdate?: Partial<IMasterSetPlanOption>
}>('updateMasterSetPlanFromId')

export function* handleUpdateMasterSetPlanFromId({ payload }: ReturnType<typeof updateMasterSetPlanFromId>) {
    try {
        const { masterSetPlanId, coordinatesToUpdate, propertiesToUpdate } = payload

        const allMasterSetPlans: ReturnType<typeof selectMasterSetPlanOptions> | null = yield select(
            selectMasterSetPlanOptions
        )

        if (allMasterSetPlans === null) return

        let currentMasterSetPlan = allMasterSetPlans.find((r) => r.id === masterSetPlanId)

        if (!currentMasterSetPlan) return

        const updatedMasterSetPlan: IMasterSetPlanOption = {
            ...currentMasterSetPlan,
            document_chunk_id: propertiesToUpdate?.document_chunk_id ?? currentMasterSetPlan.document_chunk_id,
            name: propertiesToUpdate?.name ?? currentMasterSetPlan.name,
            type: propertiesToUpdate?.type ?? currentMasterSetPlan.type,
            scale: propertiesToUpdate?.scale ?? currentMasterSetPlan.scale,
            coordinates: coordinatesToUpdate?.coordinates ?? currentMasterSetPlan.coordinates,
        }

        yield put(updateSingleMasterSetPlanState(updatedMasterSetPlan))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForUpdateMasterSetPlanFromId(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(updateMasterSetPlanFromId.type, handleUpdateMasterSetPlanFromId)
}
