import store from '../../../../stores'
import { updateMasterSetPlanFromId } from '../../../sagas/2D/updateMasterSetPlanFromId'
import { updateSelectedPaperId } from '../../../slices/2D'
import { setActiveMasterSetPlanOption } from '../../../slices/masterSetPlan'
import { IMUP2DCoordinatesToUpdate } from '../../../types'

function save2DMasterSetPlanOption(): (coordinatesToUpdate: IMUP2DCoordinatesToUpdate) => void {
    return function saveMasterSetPlanOption(this: paper.Path, coordinatesToUpdate) {
        store.dispatch(updateMasterSetPlanFromId({ coordinatesToUpdate, masterSetPlanId: this.data.masterSetPlanId }))
    }
}

function select2DMasterSetPlanOption(): () => void {
    return function selectMasterSetPlanOption(this: paper.Path) {
        store.dispatch(setActiveMasterSetPlanOption(this.data.masterSetPlanId))
        store.dispatch(updateSelectedPaperId(this.id))
    }
}

function deselect2DMasterSetPlanOption(): () => void {
    return function deselectMasterSetPlanOption(this: paper.Path) {
        store.dispatch(setActiveMasterSetPlanOption(null))
        store.dispatch(updateSelectedPaperId(null))
    }
}

export default function addSelectFunctionalityToMasterSetPlanOption(masterSetPlanOptionPath: paper.PathItem): void {
    masterSetPlanOptionPath.data.onSave = save2DMasterSetPlanOption().bind(masterSetPlanOptionPath)
    masterSetPlanOptionPath.data.onSelect = select2DMasterSetPlanOption().bind(masterSetPlanOptionPath)
    masterSetPlanOptionPath.data.onDeselect = deselect2DMasterSetPlanOption().bind(masterSetPlanOptionPath)
}
