import isNull from 'lodash/isNull'
import { Scene } from '@babylonjs/core'
import { selectMeshLabelId, updateJoistLines } from '../../slices/3D'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import managers from '../../lib/managers'
import { selectProjectId } from '../../slices/project'
import { updateOpeningLocation } from '../../../api/takeoff-api'
import { Label } from '../../lib/toolBoxes/3D'
import { getJoistBackgroundColor, getJoistTextColor } from '../../../shared/services/label-color/label-color'
import { toggleDoubleJoistByMeshId } from './data-prep/prepareJoistLinesData'

export function* handleSelectedMeshLabelId(action: ReturnType<typeof selectMeshLabelId>) {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const projectId: number | null = yield select(selectProjectId)

    if (isNull(projectId)) return

    const scene: Scene = yield call(manager.getScene)

    const mesh = yield scene.getMeshById(action.payload.id!)

    if (isNull(mesh)) return

    const { locationId } = mesh.metadata.ids?.[0]

    const labelTool = yield call(manager.getTool, Label.NAME)
    const labelContainer = yield labelTool.findLabelContainerByMesh(mesh)
    const labelContainerText = labelContainer.children[0]

    const newJoistLinesData = yield call(toggleDoubleJoistByMeshId, mesh.id)

    if (isNull(newJoistLinesData)) return

    const meshUpdatedAdditionalData = newJoistLinesData[mesh.id].metaData.additionalData

    labelContainer.background = yield call(getJoistBackgroundColor, meshUpdatedAdditionalData.is_double_joist)
    labelContainerText.color = yield call(getJoistTextColor, meshUpdatedAdditionalData.is_double_joist)

    yield put(updateJoistLines(newJoistLinesData))
    try {
        yield call(updateOpeningLocation, projectId, locationId, meshUpdatedAdditionalData)
    } catch (error) {
        yield call(console.error, error)

        const newJoistLinesData = yield call(toggleDoubleJoistByMeshId, mesh.id)
        const meshUpdatedAdditionalData = newJoistLinesData[mesh.id].metaData.additionalData

        labelContainer.background = yield call(getJoistBackgroundColor, meshUpdatedAdditionalData.is_double_joist)
        labelContainerText.color = yield call(getJoistTextColor, meshUpdatedAdditionalData.is_double_joist)

        yield put(updateJoistLines(newJoistLinesData))
    }
}

export function* watchForSelectedMeshLabelId() {
    yield takeLatest(selectMeshLabelId.type, handleSelectedMeshLabelId)
}
