import { createAction } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { call, takeEvery } from 'redux-saga/effects'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Overlay } from '../../lib/toolBoxes/2D'

export const rotateOverlayAction = createAction<{ id: number | null; rotationAngle: number }>('rotateOverlay')

export function* handleRotateOverlay(action: ReturnType<typeof rotateOverlayAction>) {
    const { id, rotationAngle } = action.payload
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager) || id === null) return

    const [overlayTool]: [Overlay] = yield call(manager.getTools, [Overlay.NAME])

    yield call(overlayTool.rotateImageRaster, id, rotationAngle)
}

export function* watchForOverlayRotation() {
    yield takeEvery(rotateOverlayAction.type, handleRotateOverlay)
}
