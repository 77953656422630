import { createAction } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { call, takeEvery } from 'redux-saga/effects'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Overlay } from '../../lib/toolBoxes/2D/tools'

export const resetOverlayPositionAction = createAction<{ id: number | null }>('resetOverlayPosition')

export function* handleResetOverlayPosition(action: ReturnType<typeof resetOverlayPositionAction>) {
    const { id } = action.payload
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager) || id === null) return

    const [overlayTool]: [Overlay] = yield call(manager.getTools, [Overlay.NAME])

    yield call(overlayTool.resetOverlayPosition, id)
}

export function* watchForOverlayPositionReset() {
    yield takeEvery(resetOverlayPositionAction.type, handleResetOverlayPosition)
}
