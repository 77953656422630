import { distinctUntilChanged, map, takeWhile } from 'rxjs'
import { initialToolsState, ToolsState } from '../../../../../slices/tools'
import { ImageSource, IMUPState, PaperToolConfig, SVGSource, VIEW_MODE } from '../../../../../types'
import Base from '../paperTool/PaperTool'

/**
 * Image.tool.tsx
 * Handles adding both raster and SVG types to a paper canvas
 */
export class Image extends Base {
    static NAME = 'IMAGE'

    private rasterSmoothing: ToolsState['rasterSmoothing'] = initialToolsState.rasterSmoothing
    protected currentRotation: number = 0
    private currentRaster: paper.Raster | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Image.NAME
        this.currentRotation = 0

        this.mediator
            .get$()
            .pipe(
                takeWhile((state: IMUPState) => state.common.activeMode === VIEW_MODE.Markup2D),
                map((state: IMUPState) => state.tools.rasterSmoothing),
                distinctUntilChanged()
            )
            .subscribe((rasterSmoothing) => {
                this.rasterSmoothing = rasterSmoothing
            })
    }

    /**
     * This method calculates the zoom level dynamically based on image dimensions provided. It finds which
     * dimension (width or height) is larger to determine the constraining dimension. It then determines if
     * that constraining dimension is larger or smaller than the canvas view. If smaller, the zoom needs to
     * increase to zoom in. If larger, the zoom needs to decrease to zoom out. There is also a concept of
     * padding which provides extra cushion around the image.
     * @param imageWidth        width of the image
     * @param imageHeight       height of the image
     * @param boundsWidth       width of the canvas view
     * @param boundsHeight      height of the canvas view
     * @param zoom              current zoom level
     * @param paddingPercentage percentage of the calculated scale factor to add onto the final zoom
     * @returns final zoom level as a number
     */
    private calculateZoomFitting(
        imageWidth: number,
        imageHeight: number,
        boundsWidth: number,
        boundsHeight: number,
        zoom: number,
        paddingPercentage: number
    ): number {
        let scalingFactor = 0

        const heightDiff = boundsHeight - imageHeight
        const widthDiff = boundsWidth - imageWidth

        // determine if there are any dimensions out of bounds as they take priority in the sizing determination. Otherwise take the largest dimension
        // height is out of bounds and a larger (negative) out of bounds diff than the image width
        if (heightDiff < 0 && heightDiff < imageWidth) {
            scalingFactor = boundsHeight / imageHeight
        }
        // width is out of bounds and a larger (negative) out of bounds diff than the image height
        else if (widthDiff < 0 && widthDiff < imageHeight) {
            scalingFactor = boundsWidth / imageWidth
        }
        // less space between bounds of height than width means it's the constraining dimension
        else if (heightDiff < widthDiff) {
            scalingFactor = boundsHeight / imageHeight
        }
        // less space between bounds of width than height means it's the constraining dimension
        else {
            scalingFactor = boundsWidth / imageWidth
        }

        const paddingFactor = 1 + paddingPercentage / 100 // increase the scaling factor by the padding percentage
        const finalZoom = zoom * (scalingFactor / paddingFactor)

        return parseFloat(finalZoom.toPrecision(2))
    }

    insertImage = (source: ImageSource): Promise<paper.Raster> =>
        new Promise((resolve) => {
            const raster = new this.paper.Raster({ source, smoothing: this.rasterSmoothing }) // Set to high to reduce loss on zoom out see: http://paperjs.org/reference/raster/#smoothing
            raster.locked = true // disable the selection of the image

            raster.onLoad = (): void => {
                // 2 magic number is used to center image around origin as opposed to putting it in upper right corner
                raster.position = new this.paper.Point(raster.image.width / 2, raster.image.height / 2)

                // set the zoom on image load to fit the image based on the dimensions
                this.paper.view.zoom = this.calculateZoomFitting(
                    raster.image.width,
                    raster.image.height,
                    this.paper.view.bounds.width,
                    this.paper.view.bounds.height,
                    this.paper.view.zoom,
                    20 // % padding around the image
                )

                // move the center of the view over to the center of the image. the view is changed (as opposed to the image coords) to maintain the coordinate system for drawables
                this.paper.view.center = new this.paper.Point(raster.image.width / 2, raster.image.height / 2)

                // update the default zoom with the calculated zoom
                // update the default center with the calculated center
                this.mediator.mediate('2D', {
                    defaultCenter: [this.paper.view.center.x, this.paper.view.center.y],
                    defaultZoom: this.paper.view.zoom,
                    maxDimension: Math.max(raster.image.width, raster.image.height),
                })

                resolve(raster)
            }

            this.currentRaster = raster
        })

    insertSVG(img: SVGSource): void {
        this.paper.project.importSVG(img, (item: paper.Item) => {
            item.position = this.paper.view.center
        })
    }

    /**
     * This function rotates the image by the given number of degrees
     */
    rotateImage = (degrees: number) => {
        if (this.currentRaster) {
            this.currentRaster.rotate(degrees)
            this.currentRotation += degrees
        }
        // We dont want to normalize the rotation here because an API call that uses
        // this method may be unsuccessful and we will have to undo
    }

    /**
     * This function resets the current rotation value of the tool to 0
     * Should be called on API success because in that case there will be nothing to undo.
     */
    normalizeRotation = () => {
        this.currentRotation = 0
    }

    /**
     * This function returns the current rotation value that has not yet been persisted to the DB
     */
    getCurrentRotation = (): number => {
        return this.currentRotation
    }

    /**
     * This function undoes the local rotate in the event of an API failure
     * Should be called on API success because in that case there will be nothing to undo.
     */
    undoRotate = () => {
        if (this.currentRaster) {
            this.currentRaster.rotate(-this.currentRotation)
            this.normalizeRotation()
        }
    }
}

export default Image
