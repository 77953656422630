import { createSelector } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import {
    ADD_BUILDING,
    ADD_DELETE_DRAWABLE_INDEX,
    ADD_DRAWABLE,
    CANCEL_ELEVATION_TOOL,
    CREATE_DRAWABLE_PENDING,
    CREATE_DRAWABLE_SUCCESS,
    DELETE_BUILDING,
    FETCH_CONFIGURATOR_SPEC_SHEET,
    FETCH_DRAWABLE_PENDING,
    FETCH_DRAWABLE_SUCCESS,
    FETCH_MAPPING_ERROR,
    FETCH_MAPPING_PENDING,
    FETCH_MAPPING_SUCCESS,
    FETCH_OPENING_GROUPS_PENDING,
    FETCH_OPENING_GROUPS_SUCCESS,
    FETCH_PROJECT_DATA_FROM_PORTAL,
    FETCH_PROJECT_SUCCESS,
    RESET_DRAWABLES,
    SET_ACTIVE_BUTTON,
    SET_ACTIVE_CHUNK,
    SET_ACTIVE_DRAWABLE,
    SET_ACTIVE_FLOOR,
    SET_CALIBRATION,
    SET_DRAWABLES,
    SET_ERROR,
    SET_FLOOR_DRAWABLES,
    SET_GROUP_STATUS,
    SET_LOCATION_ERROR_MSG,
    SET_NEW_ACTIVE_DRAWABLE_COORDINATES,
    SET_NOTIFICATION_MODAL,
    SET_PENDING,
    SET_PROJECT_BUILDINGS,
    SET_PROJECT_MODIFICATIONS,
    TOGGLE_ACTIVE_DRAWABLE,
    TOGGLE_ALL_PAGES_VIEW,
    TOGGLE_DRAWABLE_GROUPS_VISIBILITY,
    TOGGLE_DRAWABLE_ON_HOVER,
    TOGGLE_LINKING_FORM,
    TOGGLE_NOTE,
    UPDATE_BUILDING,
    UPDATE_CARD_ACTIVE_STATUS,
    UPDATE_DRAWABLE,
    UPDATE_DRAWABLE_COORDINATES,
    UPDATE_JOIST_LINES,
    UPDATE_MAPPING_ORDER,
    UPDATE_OPENING_GROUPS_SUCCESS,
    UPDATE_SCALE,
} from '../actions/drawable'
import { isMarkupProject } from '../components/markup/utils/helpers'
import { ActiveChunk } from '../models/activeChunk'
import { ActiveDrawable, Coordinate, NewDrawable, OpeningGroup } from '../models/activeDrawable'
import { ActiveFloor } from '../models/activeFloor'
import { WindowDoorElevationAI, WindowDoorFloorAI } from '../models/aiClassifications'
import { Project } from '../models/project'
import { Building } from '../models/projectBuilding'
import { Modification } from '../models/projectModification'
import { NewSpecSheetData, PortalData, SpecSheet, SpecSheetData } from '../models/specSheet'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { PLAN_VIEWS_ENUM } from '../shared/constants/plan-views'
import { PROJECT_TYPES_ENUM } from '../shared/constants/project-type.constants'
import {
    filterDrawableGroupsByFilter,
    filterDrawableGroupsPerActiveTab,
    hasDrawables,
} from '../shared/services/markup-drawable-services'
import { findIfOpeningIsAlreadyLinked } from '../shared/services/markup-le_drawables-services'
import { RootState } from '../stores'

export interface DrawableProps {
    project: Project | null
    activeFloor: ActiveFloor | null
    mapping: ActiveFloor[]
    buildings: Building[]
    modifications: Modification[]
    drawables: ActiveDrawable[]
    originalDrawableGroups: OpeningGroup[] // original state to keep track of changes before saving, should be updated ONLY after save.
    drawableGroups: OpeningGroup[]
    activeChunk: ActiveChunk
    activeDrawable: ActiveDrawable | null
    activeDrawableGroup: OpeningGroup | null
    calibration: {
        isCalibrationActive: boolean
        coordinates: Coordinate[]
    }
    isAllPagesView: boolean
    showLinkingForm: boolean
    specSheetData: SpecSheetData[]
    newSpecSheetData: NewSpecSheetData[]
    packagesSelected: string[] | []
    packagesSelectedDueDate?: string
    pagesSettings: {
        filterCardValue: { [key: string]: any }
    }
    pending: boolean
    mappingError: string | null
    error: string | null
    nextFloorExists: boolean
    locationErrorMsg: string | null
    showNotificationModal: boolean
    isShowAllTabs: boolean
    notes?: OpeningGroup[]
    highlights?: OpeningGroup[]
}

const initialState: DrawableProps = {
    project: null,
    activeFloor: null,
    mapping: [],
    buildings: [],
    modifications: [],
    originalDrawableGroups: [],
    drawables: [],
    drawableGroups: [],
    activeChunk: {
        type: PLAN_VIEWS_ENUM.FLOOR,
        index: 0,
    },
    activeDrawable: null, // used when we click on image opening on IM only
    activeDrawableGroup: null, // used when we click on a card on IM only
    calibration: {
        isCalibrationActive: false,
        coordinates: [],
    },
    isAllPagesView: true,
    showLinkingForm: false,
    specSheetData: [],
    newSpecSheetData: [],
    packagesSelected: [],
    packagesSelectedDueDate: '',
    pagesSettings: {
        filterCardValue: {},
    },
    pending: false,
    mappingError: null,
    error: null,
    nextFloorExists: false,
    locationErrorMsg: null,
    showNotificationModal: false,
    isShowAllTabs: true,
    notes: [],
    highlights: [],
}

type DrawablesAction = { type: string; error: string | null; payload: any }

const isMultibuilding = (floors: ActiveFloor[]): boolean => {
    const buildingId = floors[0].building_id
    return !!floors.find((floor) => floor.building_id !== buildingId)
}
/**
 *  Function sorts tabs in the desired order
 *  for Takeoff Page: Floor, Elevation, Schedule, Other
 *  for Markup Page: grouped by building_id & tabs with no drawables at the end
 * @param mappings
 * @param project
 * @param drawableGroups
 */
const sortProjectMappings = (
    mappings: ActiveFloor[],
    project: Project | null,
    drawableGroups: OpeningGroup[]
): ActiveFloor[] => {
    let ordering = {}
    const sortOrder = [
        PLAN_VIEWS_ENUM.FLOOR,
        PLAN_VIEWS_ENUM.ELEVATION,
        PLAN_VIEWS_ENUM.SCHEDULE,
        PLAN_VIEWS_ENUM.OTHER,
    ]
    sortOrder.forEach((type, i) => {
        ordering = {
            ...ordering,
            [sortOrder[i]]: i,
        }
    })
    const isMarkup = project && isMarkupProject(project)

    let sorted = [...mappings]

    // if order exists, sort by order and return sorted array
    if (sorted.length && sorted[0]?.order) {
        return sorted.sort(function (a: ActiveFloor, b: ActiveFloor) {
            if (a.order && b.order && a.order > b.order) return 1
            if (a.order && b.order && a.order < b.order) return -1
            return 0
        })
    }

    if (isMarkup) {
        // If it's a single building project, sort pages (no drawables at the end). Otherwise, pages will be displayed per building (no sort needed)
        if (sorted && sorted.length > 0 && !isMultibuilding(sorted)) {
            const noDrawables: ActiveFloor[] = []
            const haveDrawables: ActiveFloor[] = []

            sorted.forEach((map: ActiveFloor) => {
                if (!hasDrawables(drawableGroups, map)) {
                    noDrawables.push(map)
                } else {
                    haveDrawables.push(map)
                }
            })

            sorted = [...haveDrawables, ...noDrawables]
        }
    } else {
        sorted.sort((a, b) => {
            return ordering[a.type] - ordering[b.type] || a.name.localeCompare(b.name)
        })
    }

    return sorted
}

const calculateDrawablesFromDrawableGroups = (
    activeFloor: ActiveFloor | null,
    drawableGroups: OpeningGroup[],
    newOpeningGroup?: OpeningGroup
): OpeningGroup[] => {
    const filteredDrawableGroups = drawableGroups.filter(
        (drawableGroup) => drawableGroup.drawablesPerTab && drawableGroup.drawablesPerTab.length
    )

    // we can't use filter function here for drawables, because filter function filter from existing drawables
    // here we need get all drawables from updated groups and only from active groups
    let drawablesAfterUpdate: OpeningGroup[] = []
    filteredDrawableGroups.forEach((drawableGroup) => {
        if (
            activeFloor?.document_chunk?.id &&
            (drawableGroup.isActive[activeFloor?.document_chunk?.id] === undefined ||
                drawableGroup.isActive[activeFloor?.document_chunk?.id])
        ) {
            drawableGroup.drawablesPerTab.forEach((drawablePerTab) => {
                if (
                    (newOpeningGroup && !findIfOpeningIsAlreadyLinked(drawablePerTab, newOpeningGroup.openings)) ||
                    !newOpeningGroup
                ) {
                    let updatedDrawablePerTab = { ...drawablePerTab }
                    // Update drawable type to match the group type (the case for IMUP_WINDOWS_AND_DOORS)
                    const group = filteredDrawableGroups.find(
                        (drawableGroup) => drawableGroup.id === drawablePerTab.opening_group_id
                    )
                    if (group && drawablePerTab.type !== group.type) {
                        updatedDrawablePerTab = {
                            ...updatedDrawablePerTab,
                            type: group.type,
                        }
                    }

                    // delete the rcm_linked_opening_id after unlinking
                    if (drawablePerTab.settings.rcm_linked_opening_id) {
                        const find = drawableGroup.drawablesPerTab.find((dr) =>
                            dr.settings.linked_openings_ids?.includes(drawablePerTab.settings.rcm_linked_opening_id)
                        )

                        if (!find) {
                            updatedDrawablePerTab = {
                                ...updatedDrawablePerTab,
                                settings: {
                                    ...updatedDrawablePerTab.settings,
                                    rcm_linked_opening_id: null,
                                },
                            }
                        }
                    }

                    drawablesAfterUpdate = [...drawablesAfterUpdate, updatedDrawablePerTab as unknown as OpeningGroup]
                }
            })
        }
    })
    return drawablesAfterUpdate
}

export const drawableReducer = (state: DrawableProps = initialState, action: DrawablesAction): DrawableProps | any => {
    switch (action.type) {
        case FETCH_PROJECT_SUCCESS:
            return {
                ...state,
                project: action.payload,
            }
        case FETCH_MAPPING_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_MAPPING_SUCCESS: {
            const { project } = state
            const { floors } = action.payload
            const resultMapping: ActiveFloor[] = floors
            const sortedMappings = sortProjectMappings(resultMapping, project, [...state.drawableGroups])

            return {
                ...state,
                pending: false,
                activeFloor:
                    project?.type === PROJECT_TYPES_ENUM.WINDOW_TAKEOFF ? sortedMappings[0] : state.activeFloor,
                mapping: sortedMappings,
            }
        }
        case FETCH_MAPPING_ERROR:
            return {
                ...state,
                pending: false,
                mappingError: action.error,
            }
        case FETCH_OPENING_GROUPS_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_OPENING_GROUPS_SUCCESS: {
            const { project, mapping } = state
            // Added active as empty object to avoid js issues
            const resultGroups: OpeningGroup[] = action.payload
                ?.filter(
                    (drawableGroup) =>
                        drawableGroup.type !== DRAWABLE_TYPES.NOTE && drawableGroup.type !== DRAWABLE_TYPES.HIGHLIGHTER
                )
                ?.map((drawableGroup) => ({
                    ...drawableGroup,
                    isActive: {},
                }))

            const notes = action.payload?.filter((drawableGroup) => drawableGroup.type === DRAWABLE_TYPES.NOTE)

            const highlights = action.payload?.filter(
                (drawableGroup) => drawableGroup.type === DRAWABLE_TYPES.HIGHLIGHTER
            )

            const sortedGroupMappings = sortProjectMappings(mapping, project, resultGroups)

            return {
                ...state,
                pending: false,
                mapping: sortedGroupMappings,
                originalDrawableGroups: [...resultGroups],
                drawableGroups: resultGroups,
                notes,
                highlights,
            }
        }
        case FETCH_DRAWABLE_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_DRAWABLE_SUCCESS:
            return {
                ...state,
                pending: false,
                drawables: action.payload,
            }
        case CREATE_DRAWABLE_PENDING:
            return {
                ...state,
                pending: true,
            }
        case CREATE_DRAWABLE_SUCCESS: {
            // TODO: split create and update with different actions
            // this action works for create and update drawable on takeoff
            let drawables = [...state.drawables]
            const drawableFound = drawables.find((drawable) => drawable.id === state.activeDrawable?.drawable.id)

            if (drawableFound?.id) {
                drawables = drawables.map((drawable) => {
                    if (drawable.id === state.activeDrawable?.drawable.id) {
                        return action.payload
                    }

                    return drawable
                })
            } else {
                drawables.push(action.payload)
            }

            const updatedDrawableGroup = drawableFound?.opening_group
            if (updatedDrawableGroup) {
                drawables = drawables.map((drawable) => {
                    const drawableGroup = drawable.opening_group
                    if (drawableGroup?.id === updatedDrawableGroup.id) {
                        drawable = {
                            ...drawable,
                            opening_group: updatedDrawableGroup,
                        }
                    }

                    return drawable
                })
            }
            return {
                ...state,
                pending: false,
                activeDrawable: null,
                drawables,
            }
        }
        case SET_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case SET_PENDING:
            return {
                ...state,
                pending: true,
            }
        case SET_ACTIVE_FLOOR: {
            const activeFloor = action.payload

            const floorIndex = state.mapping.findIndex(
                (floor: ActiveFloor) => floor.document_chunk.id === activeFloor.document_chunk.id
            )
            const nextTab = state.mapping[floorIndex + 1]
            const nextFloorExists = !!nextTab && nextTab.is_floor

            return {
                ...state,
                activeFloor: action.payload,
                nextFloorExists,
                activeChunk: {
                    index: 0,
                    type: activeFloor.type,
                },
                // Do not switch to the digitizer from all pages view if you are currently on the all pages view and this is a markup only project
                isAllPagesView:
                    state.project?.type === PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY && state.isAllPagesView,
            }
        }
        case TOGGLE_ALL_PAGES_VIEW:
            return {
                ...state,
                isAllPagesView: action.payload,
            }
        case SET_FLOOR_DRAWABLES:
            return {
                ...state,
                drawables: action.payload,
            }
        case SET_ACTIVE_DRAWABLE: {
            const { drawable, index } = action.payload

            let elevationBool = false
            let floorBool = false

            if (drawable !== null) {
                const elevation = drawable.opening_locations.find(
                    (element) => element.location === PLAN_VIEWS_ENUM.ELEVATION
                )

                if (elevation && drawable.type === DRAWABLE_TYPES.FLOOR_LINE) {
                    floorBool = true
                } else if (elevation) {
                    elevationBool = true
                }

                const floor = drawable.opening_locations.find((element) => element.location === PLAN_VIEWS_ENUM.FLOOR)

                if (floor) {
                    floorBool = true
                }
            }

            return {
                ...state,
                activeDrawable: {
                    drawable,
                    index: index || state.activeDrawable?.index,
                    elevationButtonActive: elevationBool,
                    floorButtonActive: floorBool,
                },
            }
        }
        case SET_ACTIVE_CHUNK:
            return {
                ...state,
                activeChunk: action.payload,
            }
        case ADD_DRAWABLE: {
            const drawables = state.drawables.filter((drawable) => drawable.floor_hash === state.activeFloor?.hash)

            let drawableNumber = `${drawables.length + 1}`
            const typeOfDrawable = action.payload.type

            if (drawableNumber.length < 2) {
                drawableNumber = `0${drawableNumber}`
            }

            const newDrawableLabel = `${state.activeFloor?.short_name}-${drawableNumber}`

            const newDrawableHash = state.activeFloor?.hash || ''
            const newDrawable: NewDrawable = {
                label: newDrawableLabel,
                floor_hash: newDrawableHash,
                settings: {},
                opening_locations: [],
                type: typeOfDrawable,
            }
            return {
                ...state,
                activeDrawable: {
                    drawable: newDrawable,
                    index: drawables.length - 1,
                    elevationButtonActive: false,
                    floorButtonActive: false,
                },
            }
        }
        case CANCEL_ELEVATION_TOOL: {
            const acDrawable = action.payload.activeDrawable

            return {
                ...state,
                drawables: [],
                activeDrawable: {
                    ...acDrawable,
                    elevationButtonActive: false,
                    drawable: {
                        ...acDrawable.drawable,
                        opening_locations: acDrawable.drawable.opening_locations.filter(
                            (opening) => opening.location !== PLAN_VIEWS_ENUM.ELEVATION
                        ),
                        settings: {
                            ...acDrawable.drawable.settings,
                            el_width: null,
                            el_height: null,
                        },
                    },
                },
            }
        }
        case SET_ACTIVE_BUTTON: {
            const { type } = action.payload
            const returnState = { ...state }

            if (returnState.activeDrawable) {
                if (type === PLAN_VIEWS_ENUM.ELEVATION) {
                    const isActive = !returnState.activeDrawable.elevationButtonActive
                    returnState.activeDrawable.elevationButtonActive = isActive
                } else {
                    const isActive = !returnState.activeDrawable.floorButtonActive
                    returnState.activeDrawable.floorButtonActive = isActive
                }
            }

            return returnState
        }
        case SET_LOCATION_ERROR_MSG:
            return {
                ...state,
                locationErrorMsg: action.payload,
            }
        case SET_NEW_ACTIVE_DRAWABLE_COORDINATES: {
            let coordinates = action.payload

            const elevation = state.activeDrawable?.drawable.opening_locations.find(
                (element) => element.location === PLAN_VIEWS_ENUM.ELEVATION
            )
            const floor = state.activeDrawable?.drawable.opening_locations.find(
                (element) => element.location === PLAN_VIEWS_ENUM.FLOOR
            )

            if (elevation?.location === coordinates.location) {
                return state
            }

            if (floor?.location === coordinates.location) {
                return state
            }

            const drawable_coords_new = state.activeDrawable?.drawable.opening_locations

            coordinates = {
                ...coordinates,
                floor_hash: state.activeFloor?.hash,
            }

            drawable_coords_new?.push(coordinates)
            return {
                ...state,
                activeDrawable: {
                    ...state.activeDrawable,
                    drawable: {
                        ...state.activeDrawable?.drawable,
                        opening_locations: drawable_coords_new,
                    },
                },
            }
        }
        case UPDATE_DRAWABLE_COORDINATES: {
            const drawable = action.payload.drawable_coordinates
            const typeActive = action.payload.typeActive
            const drawableType = action.payload.drawable_type

            let newDrawableCoords = state.activeDrawable?.drawable.opening_locations || []

            newDrawableCoords = newDrawableCoords.map((newDrawableCoord) => {
                let updatedNewDrawableCoord = { ...newDrawableCoord }

                if (
                    newDrawableCoord.location === typeActive ||
                    (newDrawableCoord.location !== typeActive && drawableType === DRAWABLE_TYPES.FLOOR_LINE)
                ) {
                    updatedNewDrawableCoord = {
                        ...updatedNewDrawableCoord,
                        coordinates: drawable,
                    }
                }

                return updatedNewDrawableCoord
            })

            const activeDrawable = {
                ...state.activeDrawable,
                drawable: {
                    ...state.activeDrawable?.drawable,
                    opening_locations: newDrawableCoords,
                },
            }

            const drawables = state.drawables.map((drawable) => {
                if (drawable.id === activeDrawable.drawable.id) {
                    return {
                        ...drawable,
                        ...activeDrawable.drawable,
                    }
                }

                return drawable
            })

            return {
                ...state,
                activeDrawable,
                drawables,
            }
        }
        case SET_CALIBRATION:
            return {
                ...state,
                calibration: {
                    ...state.calibration,
                    isCalibrationActive: action.payload.isCalibrationActive,
                    coordinates: action.payload.coordinates,
                },
            }
        case UPDATE_SCALE: {
            // Update active floor and mapping inside mapping state
            let newActiveFloor = Object.assign({}, state.activeFloor)

            newActiveFloor = {
                ...newActiveFloor,
                scale_factor: action.payload.scaleFactor,
            }

            const mappingsList = [...state.mapping].map((mapping) => {
                let updatedMapping = { ...mapping }
                if (mapping.id === newActiveFloor.id) {
                    updatedMapping = {
                        ...updatedMapping,
                        scale_factor: action.payload.scaleFactor,
                    }
                }

                return updatedMapping
            })

            return {
                ...state,
                activeFloor: newActiveFloor,
                mapping: mappingsList,
            }
        }
        case ADD_DELETE_DRAWABLE_INDEX: {
            const pageIndex: number = state.mapping.findIndex(
                (element: ActiveFloor) => element.document_chunk.id === action.payload.documentChunkId
            )
            let mapping = [...state.mapping]

            let AIdata: WindowDoorFloorAI | WindowDoorElevationAI = JSON.parse(
                mapping[pageIndex].ai_classification.ai_classification_data
            )

            if (!AIdata.DeletedIndexes) {
                AIdata = {
                    ...AIdata,
                    DeletedIndexes: [],
                }
            }

            AIdata.DeletedIndexes?.push(action.payload.index)
            mapping = mapping.map((drawable, index) => {
                let drawableCopy = { ...drawable }
                if (pageIndex === index) {
                    drawableCopy = {
                        ...drawableCopy,
                        ai_classification: {
                            ...drawableCopy.ai_classification,
                            ai_classification_data: JSON.stringify(AIdata),
                        },
                    }
                }

                return drawableCopy
            })

            return {
                ...state,
                mapping,
            }
        }
        case TOGGLE_ACTIVE_DRAWABLE: {
            // Update drawables on the blueprint according to group state
            let activeValue

            const everyIsActive = state.activeDrawableGroup?.openings?.every((opening) => opening.isActive)
            const someIsActive = state.activeDrawableGroup?.openings?.some((opening) => opening.isActive)

            const isActiveByArrayDrawableIds = everyIsActive === someIsActive ? !everyIsActive : false

            const drawablesCopyArr = [...state.drawables].map((drawableCopyArr) => {
                let drawableCopyArrCopy = { ...drawableCopyArr }
                if (isNumber(action.payload.drawable_id) && drawableCopyArr.id === action.payload.drawable_id) {
                    drawableCopyArrCopy = {
                        ...drawableCopyArrCopy,
                        isActive: drawableCopyArr.isActive === undefined || activeValue,
                    }
                }

                if (isArray(action.payload.drawable_id) && action.payload.drawable_id.includes(drawableCopyArr.id)) {
                    drawableCopyArrCopy = {
                        ...drawableCopyArrCopy,
                        isActive: isActiveByArrayDrawableIds,
                    }
                }

                return drawableCopyArrCopy
            })

            return {
                ...state,
                drawables: drawablesCopyArr,
            }
        }
        case SET_PROJECT_BUILDINGS:
            return {
                ...state,
                buildings: action.payload.buildings,
            }
        case UPDATE_BUILDING: {
            const newBuildingsCopy = cloneDeep(state.buildings).map((b) =>
                b.id === action.payload.building.id ? action.payload.building : b
            )
            return {
                ...state,
                buildings: newBuildingsCopy,
            }
        }
        case DELETE_BUILDING:
            return {
                ...state,
                buildings: state.buildings.filter((building) => building.id !== action.payload.buildingId),
            }
        case ADD_BUILDING:
            return {
                ...state,
                buildings: [...state.buildings, action.payload],
            }
        case SET_PROJECT_MODIFICATIONS:
            return {
                ...state,
                modifications: action.payload.modifications,
            }
        case SET_NOTIFICATION_MODAL:
            return {
                ...state,
                showNotificationModal: action.payload,
            }
        case TOGGLE_DRAWABLE_ON_HOVER: {
            const copyDrawables = state.drawables.map((drawable) => {
                if (drawable.id === action.payload.drawable_id) {
                    // on icon hover, add highlighter and set to true, it will set opacity 1 to the drawable
                    // on blueprint, when mouse leave icon, we fire also this action, which will set highlighted to false
                    // and the drawable will get default opacity
                    return {
                        ...drawable,
                        highlighted: !drawable?.highlighted,
                    }
                }

                return drawable
            })

            return {
                ...state,
                drawables: copyDrawables,
            }
        }
        case UPDATE_DRAWABLE: {
            // Update drawable inside all drawables
            const drawablesCopy: ActiveDrawable[] = []
            state.drawables.forEach((dr) => {
                if (dr.id === action.payload.drawable.id) {
                    drawablesCopy.push(action.payload.drawable)
                } else {
                    drawablesCopy.push(dr)
                }
            })

            // Update drawable inside all groups
            const groupsCopy: OpeningGroup[] = []
            state.drawableGroups.forEach((gr) => {
                if (gr.id === action.payload.drawable.opening_group_id) {
                    let updatedGroup = JSON.parse(JSON.stringify(gr))
                    updatedGroup = {
                        ...updatedGroup,
                        openings: [],
                    }
                    gr.openings.forEach((opening) => {
                        if (opening.id === action.payload.drawable.id) {
                            updatedGroup.openings.push(action.payload.drawable)
                        } else {
                            updatedGroup.openings.push(opening)
                        }
                    })
                    groupsCopy.push(updatedGroup)
                } else {
                    groupsCopy.push(gr)
                }
            })

            return {
                ...state,
                drawables: drawablesCopy,
                drawableGroups: groupsCopy,
            }
        }
        case TOGGLE_LINKING_FORM:
            return {
                ...state,
                showLinkingForm: action.payload,
            }
        case SET_GROUP_STATUS:
            return {
                ...state,
                activeDrawableGroup: {
                    ...state.activeDrawableGroup,
                    settings: {
                        ...state.activeDrawableGroup?.settings,
                        isMarkedDone: action.payload.status,
                    },
                },
            }

        case FETCH_CONFIGURATOR_SPEC_SHEET:
            return {
                ...state,
                newSpecSheetData: [...action.payload],
            }

        case FETCH_PROJECT_DATA_FROM_PORTAL: {
            const { projectServicePackages } = action.payload

            // set the selectedPackages name
            const packagesSelected: string[] = []
            projectServicePackages.forEach((selectedPackage: { servicePackage: { name: string } }) => {
                packagesSelected.push(selectedPackage.servicePackage.name)
            })

            const specSheetData: SpecSheet[] = []
            const takeOffTypes = projectServicePackages.filter(
                (servicePackage: any) => servicePackage.projectPackageTakeoffTypes?.length
            )

            // some items don't have value but have @ref id, we should find where @id and @ref id are matched,
            // and we should use the value
            const getDataById = (refId: string, objectName: string, deepSearchByName?: string): null | PortalData => {
                let foundElement: null | PortalData = null
                takeOffTypes.forEach((takeOffType) => {
                    takeOffType.projectPackageTakeoffTypes.forEach((packageTakeoffType) => {
                        packageTakeoffType.projectPackageTakeoffTypeComponents.forEach((element) => {
                            // we can have data inside an object or array, for example, for locations
                            // they are inside the another array, so we need map it to find right value
                            // in case with objects we can just get value by key
                            if (!Array.isArray(element[objectName]) && element[objectName]['@id'] === refId) {
                                foundElement = element
                            } else if (
                                Array.isArray(element[objectName]) &&
                                element[objectName].length &&
                                deepSearchByName
                            ) {
                                element[objectName].forEach((material) => {
                                    if (material[deepSearchByName].length) {
                                        const foundLocation = material[deepSearchByName].find(
                                            (loc) => loc['@id'] === refId
                                        )
                                        if (foundLocation) {
                                            foundElement = foundLocation
                                        }
                                    }
                                })
                            }
                        })
                    })
                })

                return foundElement
            }

            // need this to filter spec sheet by card name
            const handleIMuPNames = (takeoffName): string[] | [] => {
                if (takeoffName?.toLowerCase() === 'roofing') {
                    return ['ev', 'pv', 'eave length', 'gable length', 'hip', 'ridge', 'valley']
                } else if (takeoffName?.toLowerCase() === 'siding / exterior trims') {
                    return ['corner', 'post wrap', 'siding', 'trim']
                } else if (takeoffName?.toLowerCase() === 'soffit / fascia / porch ceiling') {
                    return ['eave length', 'gable length', 'porch ceiling']
                }

                return []
            }

            takeOffTypes.forEach((takeOffType) => {
                takeOffType.projectPackageTakeoffTypes.forEach((type) => {
                    type.projectPackageTakeoffTypeComponents.forEach((component) => {
                        let specSheet: SpecSheet = {
                            name: null,
                            IMupNames: [],
                            applicationName: null,
                            materialsValue: null,
                            materialLabel: null,
                            locations: null,
                            selectionType: null,
                            componentName: null,
                            comments: type?.remarks || null,
                        }

                        specSheet = {
                            ...specSheet,
                            name: type.servicePackageTakeoffType.takeoffType.name,
                            IMupNames: handleIMuPNames(specSheet.name),
                        }

                        // handle application name
                        if (component?.takeoffApplication.name) {
                            // set the applicationName name is exists
                            specSheet = {
                                ...specSheet,
                                applicationName: component.takeoffApplication.name,
                            }
                        } else if (component?.takeoffApplication['@ref']) {
                            // if applicationName name doesn't exist, check the ref and get the applicationName by ref
                            const foundedComponent = getDataById(
                                component.takeoffApplication['@ref'],
                                'takeoffApplication'
                            )
                            specSheet = {
                                ...specSheet,
                                applicationName: foundedComponent && foundedComponent?.takeoffApplication.name,
                            }
                        }

                        // handle selection name
                        if (component?.takeoffTypeComponent.name) {
                            // set the applicationName is exists
                            specSheet = {
                                ...specSheet,
                                componentName: component.takeoffTypeComponent.name,
                                selectionType: component.takeoffTypeComponent.digitizerTakeoffSelectionType,
                            }
                        } else if (component?.takeoffTypeComponent['@ref']) {
                            // if applicationName name doesn't exist, check the ref and get the applicationName by ref
                            const foundedComponent = getDataById(
                                component.takeoffTypeComponent['@ref'],
                                'takeoffTypeComponent'
                            )
                            if (foundedComponent) {
                                specSheet = {
                                    ...specSheet,
                                    componentName: foundedComponent.takeoffTypeComponent.name,
                                    selectionType: foundedComponent.takeoffTypeComponent.digitizerTakeoffSelectionType,
                                }
                            }
                        }

                        // handle material name and location
                        if (component?.takeoffMaterials.length) {
                            // takeoffMaterials is an array, so probably we could have different materials
                            component.takeoffMaterials.forEach((material) => {
                                const allMaterialLocations: string[] = []

                                material.takeoffLocations.forEach((location) => {
                                    if (location?.name) {
                                        allMaterialLocations.push(location.name)
                                    } else if (location['@ref']) {
                                        const foundedComponent = getDataById(
                                            location['@ref'],
                                            'takeoffMaterials',
                                            'takeoffLocations'
                                        )
                                        foundedComponent && allMaterialLocations.push(foundedComponent.name)
                                    }
                                })

                                // push specSheet for each material
                                specSheetData.push({
                                    ...specSheet,
                                    materialsValue: material.name,
                                    locations: allMaterialLocations.join(', '),
                                })
                            })
                        } else {
                            // if material is missing push without it
                            specSheetData.push(specSheet)
                        }
                    })
                })
            })

            return {
                ...state,
                specSheetData,
                packagesSelected,
                packagesSelectedDueDate: projectServicePackages[0].dueDate,
            }
        }
        case UPDATE_CARD_ACTIVE_STATUS: {
            const { filterCardValue } = action.payload
            const pagesSettings = {
                ...state.pagesSettings,
                filterCardValue: {
                    ...state.pagesSettings.filterCardValue,
                    [state.activeFloor?.document_chunk?.id || 0]: filterCardValue,
                },
            }

            const drawableGroupsFilterCopy = filterDrawableGroupsByFilter(state.drawableGroups, filterCardValue)

            const filterDrawables: ActiveDrawable[] = []
            // handle drawables to show on blueprint, if it was hidden keep hidden, if its visible, display it
            drawableGroupsFilterCopy.forEach((drawableFilterCopy: OpeningGroup) => {
                // if isActive per page doesn't exist, push it to drawables and show
                if (
                    state.activeFloor?.document_chunk?.id &&
                    (drawableFilterCopy.isActive?.[state.activeFloor?.document_chunk?.id] || true)
                ) {
                    filterDrawables.push(...drawableFilterCopy.drawablesPerTab)
                }
            })

            return {
                ...state,
                pagesSettings,
                drawables: filterDrawables,
            }
        }
        case UPDATE_MAPPING_ORDER:
            return {
                ...state,
                mapping: action.payload.mapping,
            }

        case TOGGLE_DRAWABLE_GROUPS_VISIBILITY:
            return {
                ...state,
                isShowAllTabs: action.payload.isShowAllTabs,
            }

        case TOGGLE_NOTE: {
            const notesCopy = state.notes
                ? [...state.notes].map((note) => {
                      return {
                          ...note,
                          isActive: action.payload.toggleNoteId === note.id,
                      }
                  })
                : []

            return {
                ...state,
                notes: notesCopy,
            }
        }
        case UPDATE_JOIST_LINES:
            return {
                ...state,
                pending: false,
            }
        case RESET_DRAWABLES:
            return {
                ...initialState,
            }
        case SET_DRAWABLES:
            return {
                ...state,
                drawables: calculateDrawablesFromDrawableGroups(state.activeFloor, action.payload),
                drawableGroups: action.payload,
            }
        case UPDATE_OPENING_GROUPS_SUCCESS:
            return { ...state, pending: false }
        default:
            return state
    }
}

export const getProject = (state): Project => state.project
export const getProjectID = (state): number => state.project?.id
export const getDrawablePending = (state: DrawableProps) => state.pending
export const getDrawableMapping = (state) => state.mapping
export const getDrawableGroupsPerTab = (state: DrawableProps) => filterDrawableGroupsPerActiveTab(state.drawableGroups)
export const getAllDrawableOpeningGroups = (state: DrawableProps) => state.drawableGroups
export const getDrawableActiveFloor = (state: DrawableProps): any => state.activeFloor
export const getActiveFloor = (rootState: RootState): ActiveFloor | null => rootState.drawable.activeFloor
export const getDrawables = (state: DrawableProps) => state.drawables
export const getDrawableGroups = (state: DrawableProps) => state.drawableGroups
export const getMappingError = (state: DrawableProps) => state.mappingError
export const getDrawableActiveChunk = (state: DrawableProps) => state.activeChunk
export const getActiveDrawable = (state) => state.activeDrawable?.drawable
export const getActiveDrawableGroup = (state) => state.activeDrawableGroup
export const getLocationErrorMessage = (state: DrawableProps) => state.locationErrorMsg
export const getDrawableElevationButton = (state: DrawableProps) => state.activeDrawable?.elevationButtonActive
export const getDrawableFloorButton = (state) => state.activeDrawable?.floorButtonActive
export const getDrawableNextFloorExists = (state: DrawableProps) => state.nextFloorExists
export const getDrawableCalibration = (state: DrawableProps) => state.calibration
export const getProjectBuildings = (state: DrawableProps) => state.buildings
export const getProjectModifications = (state: DrawableProps) => state.modifications
export const getActiveBuilding = (state) => state.buildings.find((b) => b.id === state.activeFloor?.building_id ?? -1)
export const getAllPagesView = (state: DrawableProps) => state.isAllPagesView
export const getNotificationModal = (state: DrawableProps) => state.showNotificationModal
export const getShowLinkingForm = (state: DrawableProps) => state.showLinkingForm
export const getSpecSheetData = (state) => state.specSheetData
export const getNewSpecSheetData = (state: DrawableProps) => state.newSpecSheetData
export const getPackagesSelected = (state) => state.packagesSelected
export const getPackagesSelectedDueDate = (state) => state.packagesSelectedDueDate
export const getFilterCardValue = (state: DrawableProps) => state.pagesSettings.filterCardValue
export const getDrawableGroupsVisibility = (state: DrawableProps) => state.isShowAllTabs
export const getNotes = (state) => state.notes
export const getHighlights = (state) => state.highlights
export const selectActiveFloor = createSelector(
    ({ drawable }: RootState): ActiveFloor => getDrawableActiveFloor(drawable),
    (activeFloor) => activeFloor
)
