import { createAction } from '@reduxjs/toolkit'
import isUndefined from 'lodash/isUndefined'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { fetchOpeningGroupPending, setActiveDrawableGroup, updateOpeningGroupsSuccess } from '../../../actions/drawable'
import { UpdateOpeningGroupApiResponse } from '../../../api/api-helper'
import { updateJoistLines, updateOpeningGroup } from '../../../api/takeoff-api'
import { OpeningGroup } from '../../../models/activeDrawable'
import { updateDrawableSelections } from '../../../shared/services/opening-form-services'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import { formError } from '../../slices/forms'
import { prepareActiveDrawableBeforeSubmit } from '../utils/prepareActiveDrawableBeforeSubmit'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'

export const submitMaterialFormAndRefresh = createAction<{ isMarkedDone: boolean; formData: OpeningGroup['settings'] }>(
    'submitMaterialFormAndRefresh'
)

export function* handleMaterialFormSubmitAndRefresh({ payload }: ReturnType<typeof submitMaterialFormAndRefresh>) {
    try {
        const activeMode = yield select(selectActiveMode)

        const manager: PaperManager | null =
            activeMode === VIEW_MODE.Markup3D ? yield call(managers.get3DManager) : yield call(managers.get2DManager)

        if (!manager) return

        const results = yield call(prepareActiveDrawableBeforeSubmit, { formData: payload.formData })

        // Something went wrong with preparing data for submit bail
        if (!results) return

        const { activeDrawableGroup, prepareDrawables, openings, settings, configuration, remarks, scaleFactor, dpi } =
            results

        yield put(fetchOpeningGroupPending())

        const req = {
            openings: isUndefined(prepareDrawables)
                ? updateDrawableSelections(openings, {
                      settings,
                      configuration,
                      remarks,
                  })
                : prepareDrawables(
                      updateDrawableSelections(openings, {
                          settings,
                          configuration,
                          remarks,
                      }),
                      settings,
                      scaleFactor,
                      dpi
                  ),
            settings,
            type: activeDrawableGroup.type,
            configuration,
            remarks,
            is_marked_done: payload.isMarkedDone,
        }

        const res = yield call(updateOpeningGroup, activeDrawableGroup.project_id, req, String(activeDrawableGroup.id))

        const formattedResponse: UpdateOpeningGroupApiResponse = {
            newGroup: res,
            originalGroup: {
                id: activeDrawableGroup.id,
                project_id: activeDrawableGroup.project_id,
                color: null,
                ...req,
            },
        }

        yield put(updateOpeningGroupsSuccess(formattedResponse))

        yield put(setActiveDrawableGroup(formattedResponse.newGroup))

        yield put(yield call(updateJoistLines, formattedResponse.newGroup.project_id, formattedResponse.newGroup.id))

        if (activeMode === VIEW_MODE.Markup2D) {
            const [workspaceTool]: [Workspace, Select] = yield call(manager.getTools, [Workspace.NAME])

            const arrowItem: paper.Item = yield call(workspaceTool.getArrowElement)

            if (arrowItem) {
                yield call(workspaceTool.removeItemWithPaperId, arrowItem.id)
            }
        }
    } catch (error) {
        yield put(formError((error as any).message))
    }
}

export function* watchForMaterialFormSubmitAndRefresh() {
    yield takeLatest(submitMaterialFormAndRefresh.type, handleMaterialFormSubmitAndRefresh)
}
