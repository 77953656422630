import { combineReducers } from '@reduxjs/toolkit'
import slice2D from './2D'
import slice3D from './3D'
import adminSlice from './admin'
import buildingsSlice from './buildings'
import commonSlice from './common'
import documentsSlice from './documents'
import flagsSlice from './flags'
import formsSlice from './forms'
import geometrySlice from './geometry'
import loadingSlice from './loading'
import mappingsSlice from './mappings'
import masterSetPlanSlice from './masterSetPlan'
import materialFlagsSlice from './materialFlags'
import navigationSlice from './navigation'
import projectSlice from './project'
import planReviewSlice from './region'
import shortcutsSlice from './shortcuts'
import toolsSlice from './tools'
import userPermissionsSlice from './userPermissions'
import userSettingsSlice from './userSettings'

export default combineReducers({
    [adminSlice.name]: adminSlice.reducer,
    [commonSlice.name]: commonSlice.reducer,
    [toolsSlice.name]: toolsSlice.reducer,
    [slice2D.name]: slice2D.reducer,
    [slice3D.name]: slice3D.reducer,
    [projectSlice.name]: projectSlice.reducer,
    [loadingSlice.name]: loadingSlice.reducer,
    [shortcutsSlice.name]: shortcutsSlice.reducer,
    [flagsSlice.name]: flagsSlice.reducer,
    [formsSlice.name]: formsSlice.reducer,
    [navigationSlice.name]: navigationSlice.reducer,
    [geometrySlice.name]: geometrySlice.reducer,
    [planReviewSlice.name]: planReviewSlice.reducer,
    [userSettingsSlice.name]: userSettingsSlice.reducer,
    [userPermissionsSlice.name]: userPermissionsSlice.reducer,
    [documentsSlice.name]: documentsSlice.reducer,
    [mappingsSlice.name]: mappingsSlice.reducer,
    [materialFlagsSlice.name]: materialFlagsSlice.reducer,
    [buildingsSlice.name]: buildingsSlice.reducer,
    [masterSetPlanSlice.name]: masterSetPlanSlice.reducer,
})
