import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isArray from 'lodash/isArray'
import { IMasterSetPlanOption, IMasterSetPlanToCreate } from '../../models/masterSetPlan'
import { RootState } from '../../stores'

export type MasterSetPlanRegionFormFields = {
    name: string | null
    type: string | null
    scale?: string
}

export type MasterSetPlanState = {
    masterSetPlanOptions: IMasterSetPlanOption[]
    masterSetPlanToCreate: IMasterSetPlanToCreate[]
    activeMasterSetPlanOptionId: number | null
    masterSetPlanFormDataState: MasterSetPlanRegionFormFields
}

export const initialMasterSetPlanRegionFormState: MasterSetPlanRegionFormFields = {
    name: null,
    type: null,
    scale: undefined,
}

export const initialMasterSetPlanState: MasterSetPlanState = {
    activeMasterSetPlanOptionId: null,
    masterSetPlanFormDataState: initialMasterSetPlanRegionFormState,
    masterSetPlanOptions: [],
    masterSetPlanToCreate: [],
}

const masterSetPlanSlice = createSlice({
    name: 'masterSetPlan',
    initialState: initialMasterSetPlanState,
    reducers: {
        addMasterSetPlanOptionToStore: (
            state: MasterSetPlanState,
            action: PayloadAction<IMasterSetPlanOption | IMasterSetPlanOption[]>
        ) => {
            state.masterSetPlanOptions = isArray(action.payload)
                ? [...state.masterSetPlanOptions, ...action.payload]
                : [...state.masterSetPlanOptions, action.payload]
        },
        setMasterSetPlanToCreate: (state: MasterSetPlanState, action: PayloadAction<IMasterSetPlanToCreate[]>) => {
            state.masterSetPlanToCreate = [...action.payload]
        },
        resetMasterSetPlanFormState: (state: MasterSetPlanState) => {
            return {
                ...state,
                masterSetPlanRegionFormState: initialMasterSetPlanRegionFormState,
                activeMasterSetPlanOptionId: null,
            }
        },
        removeMasterSetPlanOptionById: (state: MasterSetPlanState, action: PayloadAction<number>) => {
            state.masterSetPlanOptions = state.masterSetPlanOptions.filter((option) => option.id !== action.payload)
        },
        updateMasterSetPlanOptions: (
            state: MasterSetPlanState,
            action: PayloadAction<IMasterSetPlanOption | IMasterSetPlanOption[]>
        ) => {
            state.masterSetPlanOptions = isArray(action.payload) ? action.payload : [action.payload]
        },
        setActiveMasterSetPlanOption: (state: MasterSetPlanState, action: PayloadAction<number | null>) => {
            if (action.payload === null) {
                state.activeMasterSetPlanOptionId = null
                state.masterSetPlanFormDataState = initialMasterSetPlanRegionFormState
            } else {
                const masterSetPlanOption = state.masterSetPlanOptions.find((o) => o.id === action.payload)
                if (masterSetPlanOption) {
                    state.activeMasterSetPlanOptionId = action.payload
                    state.masterSetPlanFormDataState.name = masterSetPlanOption.name
                    state.masterSetPlanFormDataState.scale = masterSetPlanOption.scale
                }
            }
        },

        updateMasterSetPlanFormState: (
            state: MasterSetPlanState,
            action: PayloadAction<Partial<MasterSetPlanRegionFormFields>>
        ) => {
            state.masterSetPlanFormDataState = {
                ...state.masterSetPlanFormDataState,
                ...action.payload,
            }
        },
        updateSingleMasterSetPlanState: (state: MasterSetPlanState, action: PayloadAction<IMasterSetPlanOption>) => {
            state.masterSetPlanOptions = state.masterSetPlanOptions.map((m) =>
                m.id === action.payload.id ? action.payload : m
            )
        },
    },
})

export const {
    addMasterSetPlanOptionToStore,
    resetMasterSetPlanFormState,
    removeMasterSetPlanOptionById,
    updateMasterSetPlanOptions,
    setActiveMasterSetPlanOption,
    updateMasterSetPlanFormState,
    setMasterSetPlanToCreate,
    updateSingleMasterSetPlanState,
} = masterSetPlanSlice.actions

export default masterSetPlanSlice

export const selectMasterSetPlanToCreate = createSelector(
    (state: RootState) => {
        return {
            masterSetPlanToCreate: state.IMUP.masterSetPlan.masterSetPlanToCreate,
            masterSetPlanFormDataState: state.IMUP.masterSetPlan.masterSetPlanFormDataState,
            masterSetPlanOptions: state.IMUP.masterSetPlan.masterSetPlanOptions,
            activeMasterSetPlanOptionId: state.IMUP.masterSetPlan.activeMasterSetPlanOptionId,
        }
    },
    (state) => state
)

export const selectMasterSetPlanOptions = createSelector(
    (state: RootState) => state.IMUP.masterSetPlan.masterSetPlanOptions,
    (state) => state
)
